import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoadingController, NavParams } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { NgxCopilotService } from 'ngx-copilot';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Appapi } from 'src/app/providers/appapi';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { OnboardingService } from 'src/app/providers/onboarding/onboarding-service';
import { UiuxService } from 'src/app/services/uiux.service';
import { WalkthroughService } from 'src/app/services/walkthrough.service';
import { getBrandName } from 'src/app/store/selectors/view.selector';
import { MyDb } from '../../../libs/MyDb';
import { MyUtil } from '../../../libs/MyUtil';
import { ProfileHeldModal } from '../../components/profile-held-modal/profile-held-modal';
import * as appStore from '../../store';
import { OrganizationsPage } from '../organizations/organizations';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'page-user-settings',
  templateUrl: 'user-settings.html',
  styleUrls: ['user-settings.scss']
})
export class UserSettingsPage implements OnInit, OnDestroy {

  // Component variables.
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  demogQuestions: any = [];
  demogCompliance: any = [];
  demogError:string;
  profileDisabled: boolean = false;
  profileOrg: any = {};
  routeData: any = {};
  errors: any = {};
  pageLabel = 'UserSettingsPage';
  isMobileView = this.uiux.isMobileView();
  brandName$: Observable<string> = this.store.select(getBrandName);
  loaded = false;

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private el: ElementRef,
    public router: Router,
    private copilot: NgxCopilotService,
    public navParams: NavParams,
    public formBuilder: UntypedFormBuilder,
    public uiux: UiuxService,
    private route: ActivatedRoute,
    public zone: NgZone,
    private store: Store<appStore.AppViewState>,
    public appapi: Appapi,
    public loadingCtrl: LoadingController,
    public desktopChecker: DesktopChecker,
    public onboardingService: OnboardingService,
    private titleService:Title) {
      this.titleService.setTitle("About You");

    // prepare profile for change
    this.pageData.profile = MyUtil.lodash.cloneDeep(MyUtil.getProfile());
    
  }

  /** 
  * Check if profile has been completed.
  */
  checkProfileComplete() {
    return MyUtil.validateProfile();
  }

  /** 
  * Re initialize and specify step.
  * @param stepNumber   stepNumber: string.
  */
  initPosition = (stepNumber: any) => this.copilot.checkInit(stepNumber);

  /** 
  * Next step.
  * @param stepNumber   stepNumber: string.
  */
  nextStep = (stepNumber: any) => this.copilot.next(stepNumber);

  /** 
   * Finish copilot walkthroughs.
   */
  done = () => this.copilot.removeWrapper();

  /** 
   * Set Walkthrough state. 
   * @param pageName   Name of page
   * @param value      Boolean - (Has been visited or not)
   */
  setWalkthroughStateHandler(pageName: string, value: boolean) {
    WalkthroughService.setWalkthroughState(pageName, value)
  }

  /** 
  * Validate Orgnization.
  * @param Key   string
  */
  private validateOrgnization(key: string): ValidatorFn {
    return (formControl: UntypedFormControl): { [key: string]: any } => {
      let result: { [key: string]: any } = null;
      let oid = formControl.value;
      let orgMap = MyUtil.cache[MyUtil.DOC_ID.USER_ORGANIZATIONS];
      if (orgMap && orgMap[oid] && orgMap[oid].children) {
        result = {};
        result[key] = true;
      }
      return result;
    }
  }

  async ngOnInit() {

    if (!this.appapi.isLoggedIn()) {
      return;
    }
    
    //let loading = MyUtil.presentLoading();
    
    await MyDb.appLoad(MyUtil.DOC_ID.APP_USER).then(appUser => {
      this.pageData.user = appUser;
    });

    this.pageData.displayHelpIcon = false;

    await this.appapi.syncUserAll().then(async () => {

      this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
        if (params.pageData) {
          this.routeData = JSON.parse(params.pageData)
        }
      });
      // expand help for first time landing on the page
      this.pageData.helperToggle = false;
      this.pageData.initialisedProfile = MyUtil.validateProfile();
      this.pageData.lockedProfile == false;

      this.pageData.filter = {};

      if (this.pageData.profile.demogQuestions?.length > 0) {
        this.demogQuestions = this.pageData.profile.demogQuestions;
      }

      if (this.pageData.profile.demogCompliance) {
        this.demogCompliance = this.pageData.profile.demogCompliance;
      }

      // init program_ids for select options
      this.pageData.programs = MyUtil.lodash.orderBy(MyUtil.lodash.values(MyUtil.getUserPrograms()), [item => item.name.toLowerCase()], ['asc']);

      // init phase_ids for select options
      this.pageData.phases = MyUtil.lodash.orderBy(MyUtil.lodash.values(MyUtil.getPhases()), [item => item.name.toLowerCase()], ['asc']);

      // init organizations for display
      let oid = this.pageData.profile.oid;
      console.log('oid', oid)
      let orgMap = MyUtil.cache[MyUtil.DOC_ID.USER_ORGANIZATIONS];
      if (orgMap && orgMap[oid] && orgMap[oid].children) {
        this.pageData.oid = null;
        this.pageData.organizations = [];
      } else {
        this.pageData.organizations = [{
          id: oid,
          name: MyUtil.getOrgnizationChainName(oid)
        }];
      }

      // check if the profile fields should be disabled
      this.profileOrg = MyUtil.getRootOrganization(this.pageData.profile.root_oid);

      if (this.profileOrg?.lock_user_profiles == 1) {
        this.profileDisabled = true;
      }

      this.pageData.onboardingEnabled = MyUtil.cache[MyUtil.DOC_ID.APP_UNIVERSITIES][this.profileOrg.id].onboarding_enabled;

      // Set up form.
      this.pageData.profileForm = this.formBuilder.group({
        first_name: [{ value: this.pageData.profile.first_name, disabled: this.profileDisabled }, Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.required])],
        last_name: [{ value: this.pageData.profile.last_name, disabled: this.profileDisabled }, Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.required])],
        program_id: [{ value: this.pageData.profile.program_id, disabled: this.profileDisabled }, Validators.compose([Validators.required])],
        phase_id: [{ value: this.pageData.profile.phase_id, disabled: this.profileDisabled }, Validators.compose([Validators.required])],
        oid: [this.pageData.profile.oid, Validators.compose([Validators.required, this.validateOrgnization('bottomLevel')])],
      });
      
      MyUtil.firebaseSetScreenName('user');
      MyUtil.firebaseLogEvent('view_did_enter', { name: 'user', data: this.routeData.data });
      
      this.checkProfile();

      //(await loading).dismiss();
      this.loaded = true;
    });
  }

  /** 
  * Check profile.
  */
  async checkProfile() {
    // force to do the profile first
    if (!MyUtil.validateProfile() && this.profileDisabled === true) {
      let messageText = '<p>Your organisation has not created an account for you with email address: '+ this.pageData.user.email +'.</p><p>Please ensure you are using the correct email address, especially if you have more than one organisation email address e.g. staff and student email addresses.</p><p>If you believe you are using the correct details and should have access to this service, please contact your organisation: ' + this.profileOrg.public_contact_email + '.</p>';
      
      if(!MyUtil.retrieveFromLocalStorage('heldModal')) {
        
        MyUtil.saveToLocalStorage('heldModal', true);

        let profileHeldModal = MyUtil.createModal(ProfileHeldModal, {
          title: "Your Account Is Currently On Hold",
          message: messageText,
          buttons: [
            {
              text: "Close",
            }
          ],
        });

        (await (profileHeldModal)).onDidDismiss().then(() => {
          MyUtil.removeFromLocalStorage('heldModal')
          this.appapi.forgetAppUser(true).then(() => {
            MyUtil.presentToast('Logged out successfully!', { cssClass: 'inkpath-toast' });
            this.router.navigate(['/LoginPage']);
  
          });
        });

        (await (profileHeldModal)).present();

      }

    }
    this.startWalkthrough();
  }

  /** 
  * Start Walkthrough.
  */
  startWalkthrough() {
    if (!WalkthroughService.isWalkthroughComplete(this.pageLabel) && !WalkthroughService.allWalkthroughsDisabled()) {
      setTimeout(() => {
        this.copilot.checkInit('12');
      }, 1000);
    }
    this.pageData.displayHelpIcon = !WalkthroughService.allWalkthroughsDisabled();
  }


  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', { name: 'user', data: this.routeData.data });
  }

  ionViewCanLeave() {
    if (this.pageData.profileForm.dirty) {
      this.process('save-profile');
    }
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  */
  async process(action) {

    if (action == 'choose-organization') {
      if (this.profileDisabled) {
        return;
      }

      let organizationsModal = MyUtil.createModal(OrganizationsPage, { top: this.profileOrg });
      (await organizationsModal).onDidDismiss().then((data: any) => {
        if (data.data) {
          this.pageData.organizations = [{
            id: data.data.id,
            name: MyUtil.getOrgnizationChainName(data.data.id)
          }];
          this.pageData.profileForm.controls.oid.setErrors(null);
          this.pageData.profileForm.controls.oid.setValue(data.data.id);
          this.pageData.profileForm.controls.oid.markAsDirty();
        }
      });
      
      (await organizationsModal).present();
      
      return;

    } else if (this.pageData.profileForm.dirty || action == 'save-profile') {
      setTimeout(()=>{
        const invalidControl = this.el.nativeElement.querySelector('.invalid-field');
        if (invalidControl) {
          invalidControl.focus();
        }
      }, 200)

      // set the demographics data
      if (this.demogQuestions?.length > 0) {
        this.pageData.profile.demogQuestions = this.demogQuestions;
      }

      let demogItems = this.demogQuestions.filter(item => item.answer);
      this.pageData.profileFormSubmitAttempt = true;
      if (!this.pageData.profileForm.valid || demogItems?.length < this.demogQuestions?.length) {
        this.errors = MyUtil.getFormValidationErrors(this.pageData.profileForm, {
          first_name: 'First name is required',
          last_name: 'Last name is required',
          program_id: 'Programme is required',
          phase_id: 'Year is required',
          oid: 'Bottom level organisation is required'
        });

        // check demographic questions have been completed
        if (demogItems?.length < this.demogQuestions?.length) {
          this.demogError = "Please complete the additional profile questions.";
        } else {
          this.demogError = '';
        }

        return;
      }

      MyUtil.lodash.merge(this.pageData.profile, this.pageData.profileForm.value);
      this.saveUserProfile().then(() => {
        this.pageData.profileForm.reset(this.pageData.profile);
        this.postProcess(action);
      });

    } else if(action == 'delete-account') {
      const alert = MyUtil.presentAlert({
        title: 'Confirm Account Deletion Request',
        message: "Are you sure you want to delete your account? Your request will be passed to the associated organisation.",
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              return;
            }
          },
          {
            text: 'Confirm',
            handler: () => {
              this.appapi.deleteUserRequest().then( (message) => {
                MyUtil.presentToast(message, { cssClass: 'inkpath-toast' });
              });
            }
          }
        ]
      });

    } else {
      this.postProcess(action);
    }
  }

  /** 
  * Actions.
  * @param actionItem   Name of action (string).
  */
  postProcess(action) {
    switch (action) {
      case 'logout':
        this.logout();
        return;
      case 'change-password':
        this.router.navigate(['/PasswordPage', JSON.stringify({ 'initialisedProfile': this.pageData.initialisedProfile })]).catch(err => {
          MyUtil.debug(['navigate', err]);
        });
        return;
      case 'funding-list':
        this.router.navigate(['/FundingListPage', JSON.stringify({ 'initialisedProfile': this.pageData.initialisedProfile })]).catch(err => {
          MyUtil.debug(['navigate', err]);
        });
        return;
      case 'onboarding':
        this.router.navigate(['/OnboardingPage', '{}']);
        return;
      case 'diagnostic':
        this.router.navigate(['/DiagnosticListPage', '{}']);
        return;
      case 'save-profile':
        if (this.profileOrg.affiliate_redirect == 1 && !this.pageData.profile.fundings) {
          this.router.navigate(['/FundingListPage', JSON.stringify({ 'initialisedProfile': this.pageData.initialisedProfile })]);
        }
        return;

      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }

  // save and sync profile, then reload master data accordingly
  // similar procedure happens when changing my fundings
  private saveUserProfile() {
    let loading = MyUtil.presentLoading();
    return MyDb.userSave({
      _id: MyUtil.DOC_ID.USER_PROFILE,
      // update timestamp to enable sync
      ts: MyUtil.getUnixTimeStamp(),
      // updated profile data
      data: this.pageData.profile
    }).then((doc) => {
      this.store.dispatch(new appStore.usernameSet(`${this.pageData.profileForm.controls.first_name.value} ${this.pageData.profileForm.controls.last_name.value}`));
      // update the cache with the changed doc data
      MyUtil.cache[MyUtil.DOC_ID.USER_PROFILE] = doc.data;

      return this.appapi.clearTimestampToForceFullSync().then(() => {
        return this.appapi.syncUserAll().then(async () => {
          (await loading).dismiss();

          // update with the server returned data in cache in case server has newer profile
          this.pageData.profile = MyUtil.lodash.cloneDeep(MyUtil.getProfile());

          await this.onboardingService.checkDisplayOnboardingPages().then(response => {
         
            if (this.routeData.redirect) {
              if (this.profileOrg.affiliate_redirect !== 1 && !this.pageData.onboardingEnabled) {
                MyUtil.presentToast('Settings data saved. Please continue to choose your goals or activities', { cssClass: 'inkpath-toast' });
              }
              if (response.display == 1) {
                this.router.navigate(['/OnboardingPage', '{}']);
              } else {
                this.router.navigate(['/']);
              }
            } else if (this.routeData.referer) {
              if (response.display == 1) {
                if (this.routeData.referer === "onboarding") {
                  this.router.navigate(['/OnboardingPage', '{}']);
                } else {
                  this.router.navigate(['/']);
                }
              } else {
                this.router.navigate(['/']);
              }
            } else {
              MyUtil.presentToast('Settings data saved.', { cssClass: 'inkpath-toast' });
            }
          });
        });
      });
    }).catch(async err => {
      (await loading).dismiss();
      MyUtil.error(err);
    });
  }

  /**
  * Logout.
  */
  private logout() {
    MyUtil.checkFormUnsavedChanges(this.pageData.profileForm, this.pageData.profile).then(() => {
      MyUtil.presentAlert({
        title: 'Confirm logout',
        message: 'Are you sure?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel'
          },
          {
            text: 'Logout',
            handler: () => {
              this.appapi.forgetAppUser(true).then(() => {
                MyUtil.presentToast('Logged out successfully!', { cssClass: 'inkpath-toast' });
                // this.navCtrl.setRoot(LoginPage);
                this.router.navigate(['/LoginPage']);

              });
            }
          }
        ],
      });
    }).catch(err => {
      MyUtil.debug(['navigate', err]);
    });
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
