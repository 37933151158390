import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MyDb } from 'src/libs/MyDb';
import { MyUtil } from 'src/libs/MyUtil';
import { Appapi } from "../appapi";
import { Store } from '@ngrx/store';
import * as appStore from '../../store';

import { OnboardingService } from '../onboarding/onboarding-service';

/*
  Generated class for the AuthProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root',
})
export class AuthProvider {

  constructor(public appapi: Appapi,
    private zone: NgZone,
    private store: Store<appStore.AppViewState>,
     public onboardingService: OnboardingService,
    public router: Router) {
  }

  loginUser(userData, switchingOrg: boolean = false) {

    // checked for locked accounts
    if(userData.status === "0" || userData.status === 0) {
      MyUtil.presentToast('Your account is currently blocked. Please contact your organisation administrator.', { cssClass: 'inkpath-toast' });

     return this.appapi.forgetAppUser(true).then(() => {
        userData = {};
        this.router.navigate(['/home']);
      });
    }

    MyUtil.firebaseLogEvent('login_success', {'email': userData.email});

    let appUser: any = {_id: MyUtil.DOC_ID.APP_USER};
    MyUtil.lodash.merge(appUser, userData);
    MyUtil.saveToLocalStorage('localOid', appUser.oid);

    MyDb.appSave(appUser).then(userDoc => {
      
      return this.appapi.initializeAppUser().then(async (user) => {

        // load user cache and try sync from server
        return this.appapi.syncUserAll().then(async (test) => {
          
          // check if we need to display onboarding pages to the user
          await this.onboardingService.checkDisplayOnboardingPages().then(response => {
            
            this.store.dispatch(new appStore.AppToOnboardingSet(response));

            // check if profile has been completed
            if (!MyUtil.validateProfile()) {
              this.zone.run(()=>{
                this.router.navigate(['/UserSettingsPage', JSON.stringify({ "redirect": 'onboarding' })]); 
              })
            } else if (response?.display === 1) {
              this.zone.run(()=>{ 
                this.router.navigate(['/OnboardingPage', '{}']);
              });

            } else {
              this.zone.run(() => {
                if(switchingOrg) {
                  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                  this.router.onSameUrlNavigation = 'reload';
                }
                this.router.navigate(['/']);
              })
            }
          });

          // Check if user has completed onboarding questionnaire
          return this.appapi.getMostRecentQuestionnaireResults().then(async res => {
            //(await load).dismiss();
            MyUtil.publishEvent(MyUtil.EVENT.APP_TO_QUESTIONNAIRE, res);
          });
        })
      });
    
    }).catch(async err => {
      MyUtil.error(err);
    })
  }

  /**
   * Auth for the RSC login page
   * @param userData 
   * @param loading 
   */
  loginRscUser(userData, loading = null) {
    MyUtil.firebaseLogEvent('login_success', {'email': userData.email});
    let appUser: any = {_id: MyUtil.DOC_ID.APP_USER};
    MyUtil.lodash.merge(appUser, userData);
    MyUtil.saveToLocalStorage('localOid', userData.oid);
    MyDb.appSave(appUser).then(userDoc => {

      return this.appapi.initializeAppUser().then((user) => {

        // load user cache and try sync from server
        this.appapi.syncUserAll().then(async () => {


          // prepare to handle go to questionnaire
          this.onboardingService.checkDisplayOnboardingPages().then(async response => {


            // check if profile has been completed
            if (!MyUtil.validateProfile()) {

              (await loading).dismiss();
            //  await this.appapi.syncUserAll().then(async () => {

              this.zone.run(()=>{
                this.router.navigate(['/UserSettingsPage', JSON.stringify({ "redirect": 'onboarding' })]);
              });
             // });

            } else if (response?.display === 1) {
              (await loading).dismiss();
            this.zone.run(()=>{
              this.router.navigate(['/OnboardingPage', JSON.stringify({ "referer": 'onboarding' })]);
            });
            } else {
              (await loading).dismiss();
              // prepare to handle go to questionnaire
              // MyUtil.subscribeEvent(MyUtil.EVENT.APP_TO_QUESTIONNAIRE, (res: any) => {
              //   MyUtil.unsubscribeEvent(MyUtil.EVENT.APP_TO_QUESTIONNAIRE);
                //if (res['#status'] === 'success') {
                  this.router.navigate(['/home']);
                // } else {
                //   if (!res['#skipQuestionnaire']) {
                //     this.router.navigate(['/SkipQuestionnaire'])
                //   } else {
                //     this.router.navigate(['/ActivitiesPage'])
                //   }
                // }
              //});
            }
          });
        })

        // Check if user has completed onboarding questionnaire
        return this.appapi.getMostRecentQuestionnaireResults().then(async res => {
          //(await load).dismiss();
          MyUtil.publishEvent(MyUtil.EVENT.APP_TO_QUESTIONNAIRE, res);
        });
        
      });
    }).catch(async err => {
      (await loading).dismiss();
      MyUtil.error(err);
    });
  }

  logout() {
    MyUtil.presentAlert({
      title: 'Confirm logout',
      message: 'Are you sure?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Logout',
          handler: () => {
            this.appapi.forgetAppUser(true).then(() => {
              MyUtil.presentToast('Logged out successfully!', { cssClass: 'inkpath-toast' });
              
              // handle log out if for the RSC
              let hostname = window.location.hostname;
                
              if(hostname === MyUtil.context.RSC_URL) {
                MyUtil.removeFromLocalStorage('rsc-session');
                this.router.navigate(['/rsc/auth']);
              } else {
                this.router.navigate(['/LoginPage']);
              }
          
            });
          }
        }
      ],
    });
  }

  

}
